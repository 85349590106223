import dayjs from "dayjs";
import { useReport } from "~/composables/useReport";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import isErrorWithMessage from "~/helpers/isErrorWithMessage";
import type { UserProfile } from "~/types/User";

async function updateProfile({
  callingCode,
  phone,
  birthDay,
  lang,
  name,
  email,
  password,
  avatar,
}: {
  callingCode?: string;
  phone?: string;
  birthDay?: string;
  lang?: string;
  name?: string;
  email?: string;
  password?: string;
  avatar?: string;
}) {
  const userStore = (await import("~/stores/user")).default;
  const useUserStore = userStore();
  try {
    const { updateProfile } = await import("~/api/user/updateProfile");
    useUserStore.isLoading = true;
    const payload = [];
    if (avatar) {
      payload.push({
        key: "avatar",
        val: avatar,
      });
    }
    if (callingCode) {
      payload.push({
        key: "calling_code",
        val: callingCode,
      });
    }
    if (phone) {
      payload.push({
        key: "phone",
        val: phone,
      });
    }
    if (birthDay) {
      payload.push({
        key: "birthday",
        val: birthDay,
      });
    }
    if (lang) {
      payload.push({
        key: "language",
        val: lang,
      });
    }
    if (name) {
      payload.push({
        key: "name",
        val: name,
      });
    }
    if (email) {
      payload.push({
        key: "new_email",
        val: email,
      });
    }
    if (password) {
      payload.push({
        key: "new_password",
        val: password,
      });
    }
    const response = await updateProfile({
      token: useUserStore.accessToken,
      updateField: payload,
    });
    if (response.isSuccess === true) {
      return {
        isSuccess: true,
        data: response.data,
        message: response.message,
      };
    }
    return {
      isSuccess: false,
      data: response.data,
      message: response.message,
    };
  } catch (err: any) {
    return {
      isSuccess: false,
      data: null,
      message: err,
    };
  } finally {
    useUserStore.isLoading = false;
  }
}

async function fetchProfile() {
  const userStore = (await import("~/stores/user")).default;
  const useUserStore = userStore();
  try {
    useUserStore.isLoading = true;
    const { getProfile } = await import("~/api/user/getProfile");
    const { isSuccess, message, data, httpStatus } = await getProfile(
      useUserStore.accessToken
    );
    if (isSuccess) {
      if (data) await setProfile(data);
      return { isSuccess: true, httpStatus };
    } else {
      if (message === "invalid-credential") {
        const { doSignOut } = await import("~/services/auth/signOut");

        doSignOut();
        return {
          isSuccess: false,
          httpStatus,
        };
      }
      return { isSuccess: false, message, httpStatus };
    }
  } catch (err) {
    const errorMessage = isErrorWithMessage(err)
      ? err.message
      : "Oops, something went wrong, failed to get profile";
    useReport({
      level: "error",
      message: errorMessage,
      errorException: err,
    });
    return { isSuccess: false, message: errorMessage, httpStatus: 0 };
  } finally {
    useUserStore.isLoading = false;
  }
}

async function setProfile(data: UserProfile) {
  try {
    const userStore = (await import("~/stores/user")).default;
    const useUserStore = userStore();
    const {
      storageSetAccessToken,
      storageSetAccessTokenExpiredIn,
      storageSetRefreshToken,
    } = await import("~/composables/accessToken");
    useUserStore.flag = typeof data.flag === "boolean" ? data.flag : false;
    useUserStore.id = data.id;
    useUserStore.name = data.name;
    useUserStore.email = data.email;
    useUserStore.phone = data.phone;
    useUserStore.phoneCode = data.callingCode
      ? data.callingCode[0] === "+"
        ? data.callingCode
        : `+${data.callingCode}`
      : "";
    useUserStore.phoneCodeCountry = data.countryCallingCode || "";
    useUserStore.accessToken = data.accessToken;
    useUserStore.referralCode = data.rCode;
    useUserStore.points = data.points;
    useUserStore.birthDay = data.birthday || "";
    useUserStore.avatarThumb = data.avatar.original;
    useUserStore.avatarOriginal = data.avatar.original;
    useUserStore.cleverTapObj = data.clevertapProps;
    useUserStore.loyaltyLevel = data.loyaltyLevel;
    useUserStore.loyaltyLevelId = data.loyaltyLevelId;
    useUserStore.topLoyaltyLevelId = data.topLoyaltyLevelId;
    useUserStore.loyaltyLevelExpiryDate =
      dayjs(data.loyaltyLevelExpiryDate).format("DD/MM/YYYY") || "";
    useUserStore.loyaltyIconBadge = data.iconBadge?.url;
    useUserStore.totalSpend = data.totalSpend;
    useUserStore.totalReservation = data.totalReservations;
    useUserStore.progressTotalReservations = data.progressTotalReservations;
    useUserStore.progressTotalSpend = data.progressTotalSpend;
    useUserStore.language = data.language;
    useUserStore.startDateLevel =
      dayjs(data.startDateLevel).format("DD/MM/YYYY") || "";
    useUserStore.refreshToken = data.refreshToken;
    // set token to local storage
    storageSetAccessToken(data.accessToken);
    storageSetAccessTokenExpiredIn(data.expiresIn);
    storageSetRefreshToken(data.refreshToken);
  } catch (err) {
    useReport({
      level: "error",
      message: `${DEFAULT_ERROR_MESSAGE}, failed to set profile`,
      errorException: err,
    });
  }
}

export { updateProfile, fetchProfile, setProfile };
